.background {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  padding: 10px;
}

.active-area {
  background-color: white;
  opacity: 0.9;
  padding: 5px;
  min-height: calc(100vh - 20px);
  min-height: calc(calc(var(--vh, 1vh) * 100) - 20px);
  max-height: min(calc(calc(var(--vh, 1vh) * 100) - 20px), 700px);
  min-width: min(1000px, calc(100vw - 20px));
  max-width: 1000px;
}

.active-contents {
  min-height: calc(100vh - 30px);
  min-height: calc(calc(var(--vh, 1vh) * 100) - 30px);
  max-height: min(calc(calc(var(--vh, 1vh) * 100) - 30px), 690px);
  min-width: min(980px, calc(100vw - 30px));
  max-width: 990px;
}

.active-contents.flex-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
